<!-- 人力资源编辑 -->
<template>
    <div class="editHuman">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">人力资源编辑</span>
            <el-button class="el-btn" size="small" @click="goBack()">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="员工工号">
                <el-input v-model="form.jobNumber" placeholder="请输入员工工号"></el-input>
            </el-form-item>
            <el-form-item label="人员姓名" prop="fullName">
                <el-input v-model="form.fullName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="英文名字">
                <el-input v-model="form.englishName" placeholder="请输入英文名字"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phoneNumber">
                <el-input v-model="form.phoneNumber" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input v-model="form.telephone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="文化程度">
                <el-input v-model="form.education" placeholder="请输入文化程度"></el-input>
            </el-form-item>
            <el-form-item label="出生日期">
                <el-date-picker v-model="form.birthday" value-format="yyyy-MM-dd" type="date" 
                placeholder="请选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="工作场地">
                <el-input v-model="form.workplace" placeholder="请输入工作场地"></el-input>
            </el-form-item>
            <el-form-item label="所属岗位">
                <el-input v-model="form.station" placeholder="请输入所属岗位"></el-input>
            </el-form-item>
            <el-form-item label="所在部门">
                <el-input v-model="form.department" placeholder="请输入所在部门"></el-input>
            </el-form-item>
            <el-form-item label="办公场地">
                <el-input v-model="form.officeSpace" placeholder="请输入办公场地"></el-input>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择状态">
                    <el-option v-for="item in form.stateChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="入职日期">
                <el-date-picker v-model="form.entryDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="员工类型">
                <el-select v-model="form.employeeType" placeholder="请选择员工类型">
                    <el-option v-for="item in form.typeChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="性别">
                <div id="sex" style="width: 300px;">
                    <el-radio-group v-model="form.sex">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item label="户籍所在地">
                <el-input v-model="form.register" placeholder="请输入户籍所在地"></el-input>
            </el-form-item>
            <el-form-item label="籍贯">
                <el-input v-model="form.nativePlace" placeholder="请输入籍贯"></el-input>
            </el-form-item>
            <el-form-item label="是否已婚">
                <div id="marriage" style="width: 300px;">
                    <el-radio-group v-model="form.marriage">
                        <el-radio :label="1">已婚</el-radio>
                        <el-radio :label="2">未婚</el-radio>
                    </el-radio-group>

                </div>
            </el-form-item>
            <el-form-item label="居住证">
                <el-input v-model="form.residence" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="劳动合同签订">
                <el-select v-model="form.contracts" placeholder="请选择">
                    <el-option v-for="item in form.signChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="社保">
                <el-select v-model="form.socialInsurance" placeholder="请选择">
                    <el-option v-for="item in form.socialChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="家庭住址">
                <el-input v-model="form.homeAddress" placeholder="请输入家庭住址"></el-input>
            </el-form-item>
            <el-form-item label="现居住地">
                <el-input v-model="form.residential" placeholder="请输入现居住地"></el-input>
            </el-form-item>
            <el-form-item label="民族">
                <el-input v-model="form.nation" placeholder="请输入民族"></el-input>
            </el-form-item>
            <el-form-item label="登录账号">
               <el-input v-model="form.userName" placeholder="请输入登录账号"></el-input>
            </el-form-item>
            <el-form-item label="登录密码">
               <el-input v-model="form.pwd" show-password placeholder="不修改密码保留空白"></el-input>
            </el-form-item>
            <el-form-item label="所属角色">
               <el-select v-model="form.part" placeholder="请选择所属角色" :clearable="true">
                   <el-option v-for="item in form.partChoose" :key="item.id"
                       :label="item.name" :value="item.id">
                   </el-option>
               </el-select>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    jobNumber: "", //员工工号
                    fullName: "", //人员姓名
                    englishName: "", //英文名字
                    phoneNumber: "", //手机号
                    telephone: "", //联系电话
                    education: "", //文化程度
                    birthday: '', //出生日期
                    workplace: "", //工作场地
                    station: "", //所属岗位
                    department: "", //所在部门
                    officeSpace: "", //办公场地
                    stateChoose: [{
                            label: "在职",
                            value: "1"
                        },
                        {
                            label: "新人",
                            value: "2"
                        },
                        {
                            label: "兼职",
                            value: "3"
                        },
                        {
                            label: "离职",
                            value: "4"
                        },
                    ], //状态选择
                    condition: "", //状态 
                    entryDate: "", //入职日期
                    typeChoose: [ //员工类型选择
                        {
                            label: "正式员工",
                            value: '1'
                        },
                        {
                            label: "实习生",
                            value: '2'
                        },
                        {
                            label: "试用员工",
                            value: '3'
                        },
                        {
                            label: "其他",
                            value: '4'
                        },
                    ],
                    employeeType: "", //员工类型
                    sex: 1, //性别
                    register: "", //户籍所在地
                    nativePlace: "", //籍贯
                    marriage: '', //婚姻状况
                    residence: "", //居住证
                    signChoose: [ //劳动合同签订选择
                        {
                            label: "签订合同",
                            value: '1'
                        },
                        {
                            label: "未签合同",
                            value: '2'
                        },
                        {
                            label: "不确定",
                            value: '3'
                        },
                    ],
                    contracts: "", //劳动合同签订
                    socialChoose: [ //社保选择
                        {
                            label: "有",
                            value: '1'
                        },
                        {
                            label: "没有",
                            value:'2'
                        },
                    ],
                    socialInsurance: "", //社保
                    homeAddress: "", //家庭住址
                    residential: "", //现居住地
                    nation: "" ,//民族
                    userName:"",//登录账号
                    pwd:"",//登录密码
                    part:"",//所属角色
                    partChoose:[]//所属角色选项
                },
                rules: {
                    fullName: [{
                            required: true,
                            message: '请输入人员姓名',
                            trigger: 'blur'
                        },
                        {
                            min: 1,
                            max: 5,
                            message: '长度在 1 到 5 个字符',
                            trigger: 'blur'
                        },
                    ],
                    phoneNumber: [{
                        required: true,
                        message: '请输入联系电话',
                        trigger: 'blur'
                    }, ]
                },
                bool: false
            }
        },
        created() { 
            // 获取角色
            this.$request({
                url:'/api/user/power_list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                // console.log(res,'获取角色')
                this.form.partChoose=res.data.list
            })
            //初次渲染
            this.$request({url:'/api/humanresources/detail',method:'POST',data:{
                id: this.$route.query.id
            }}).then(res=>{
                console.log(res,'详情')
                let data = res.data.data
                
                this.form.jobNumber =data.jobnumber  //员工工号
                this.form.fullName =data.fullname  //人员姓名
                this.form.englishName = data.englishname //英文名字
                this.form.phoneNumber = data.mobile //手机号
                this.form.telephone = data.telephone //联系电话
                this.form.education =data.education  //文化程度
                this.form.birthday =data.birthdate  //出生日期
                this.form.workplace = data.workplace //工作场地
                this.form.station =data.position //所属岗位
                this.form.department = data.department //所在部门
                this.form.officeSpace = data.office_space //办公场地
                this.form.condition = data.status+'' //状态
                this.form.entryDate = data.entry_date //入职日期
                this.form.employeeType = data.jobtype +''//员工类型
                this.form.sex = data.gender //性别
                this.form.register = data.census_register //户籍所在地
                this.form.nativePlace =data.native_place //籍贯
                this.form.marriage = data.is_marry //婚姻状况
                this.form.residence = data.residence_permit //居住证
                this.form.contracts = data.labor_contract+'' //劳动合同签订
                this.form.socialInsurance = data.social_insurance+'' //社保
                this.form.homeAddress = data.address //家庭住址
                this.form.residential = data.habitation //现居住地
                this.form.nation = data.nation //民族
                this.form.userName = data.username//登录账号
                this.form.pwd = data.password//登录密码
                this.form.part = data.group_id//所属角色   
            })
            
        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        if (this.bool) {
                            return
                        }
                        this.bool = true
                        // console.log(this.form)
                        let obj = {
                            jobnumber: this.form.jobNumber, //员工工号
                            fullname: this.form.fullName, //人员姓名
                            englishname: this.form.englishName, //英文名字
                            mobile: this.form.phoneNumber, //手机号
                            telephone: this.form.telephone, //联系电话
                            education: this.form.education, //文化程度
                            birthdate: this.form.birthday, //出生日期
                            workplace: this.form.workplace, //工作场地
                            position: this.form.station, //所在岗位
                            department: this.form.department, //所在部门
                            office_space: this.form.officeSpace, //办公场地
                            status: this.form.condition, //状态
                            entry_date: this.form.entryDate, //入职时间
                            jobtype: this.form.employeeType, //员工类型
                            gender: this.form.sex, //性别
                            census_register: this.form.register, //户籍
                            native_place: this.form.nativePlace, //籍贯
                            is_marry: this.form.marriage, //婚姻状况
                            residence_permit: this.form.residence, //居住证
                            labor_contract: this.form.contracts, //劳动合同签订
                            social_insurance: this.form.socialInsurance, //社保
                            address: this.form.homeAddress, //家庭地址
                            habitation: this.form.residential, //居住地
                            nation: this.form.nation, //民族
                            username:this.form.userName,//登录账号
                            password:this.form.pwd,//登录密码
                            group_id:this.form.part,//角色id
                            id: this.$route.query.id
                        }

                        this.$request({
                            url: "/api/humanresources/edit",
                            method: "POST",
                            data: obj
                        }).then(res => {
                            if(res.code==1){
                                this.$message({
                                  message:"编辑列表成功",
                                  type: 'success'
                                });
                                setTimeout(() => {
                                  this.goBack()	
                                }, 1500)
                            }else{
                                this.$message({
                                  message:res.msg,
                                  type: 'error'
                                });
                                setTimeout(() => {
                                  this.goBack()	
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })  
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.goBack()
                this.$refs[form].resetFields();
            }
        }
    }
</script>

<style scoped="scoped">
    .editHuman {
        width: 100%;
        height: 100%;
    }

    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }

    .el-input {
        width: 300px;
        height: 37px !important;
    }
    #form-1 ::v-deep .el-form-item__label{
            color: #333 !important;
    }
    .el-select {
        width: 300px;
    }

    /* 性别选择按钮 */
    #form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;

    }

    #form-1 ::v-deep.el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }

    #form-1 ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
</style>
